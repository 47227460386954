import React from "react";
import dateFormat from "dateformat";

import app_params from "../../config/params";

const Footer = () => {
  var now = new Date();
  return (
    <footer className={`text-center text-lg-start theme-footer`}>
      <section className="border-bottom mb-1">
        <div className="container text-center text-md-start mt-4">
          <div className="row">
            <div className="col-12 col-lg-6 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 mt-3">Contacto</h6>
              <p>
                <i className="fas fa-home me-3"></i> Secretaría
                Nacional de Ciencia y Tecnología (Senacyt) <br/>3av. 13-28 Zona 1,
                Guatemala
              </p>
              <p>
                <a href="mailto:glifos@senacyt.gob.gt">
                  <i className="fas fa-envelope me-3"></i>
                  glifos@senacyt.gob.gt
                </a>
              </p>
              <p>
                <a href="tel:23172600">
                  <i className="fas fa-phone-alt me-3"></i>
                  (502) 2317-2600 Ext. 169/197
                </a>
              </p>
              <p>
                <i className="fas fa-calendar me-3"></i> De 08:00 a 16:00 horas
                de lunes a viernes
              </p>
            </div>
            <div className="col-12 col-lg-6 mx-auto mb-3">
              <h6 className="text-uppercase fw-bold mb-4">
                Biblioteca Secretaría Nacional de Ciencia y Tecnología (Senacyt)
              </h6>
              <p>Síguenos en nuestras redes sociales</p>
              <div>
                <a
                  href="https://www.facebook.com/senacytgt/"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.instagram.com/senacytgt/"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://twitter.com/senacytgt"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-twitter-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/senacytgt/"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
              <div className="d-flex">
                <img
                  src={`${app_params.basepath}/assets/logos/senacyt.png`}
                  alt="slogan"
                  className="d-inline-block eslogan"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-lg-flex flex-row justify-content-center p-4">
        <div className="d-flex flex-row justify-content-center mx-lg-5">
          <span className="glifos-font">GLIFOS - library</span>
          <span className="ms-1">ver. {app_params.version}</span>
        </div>
        <div className="d-flex flex-row justify-content-center mt-3 mt-lg-0 mx-lg-5">
          Copyright &copy; 2000 - {dateFormat(now, "yyyy")} Infolib, S.A.
        </div>
        <div className="d-flex flex-column justify-content-center mt-3 mt-lg-0 mx-lg-5">
          <div className="d-flex flex-row justify-content-center">
            <a href="https://glifos.com" target="_blank" rel="noreferrer">
              <img
                src={`${app_params.basepath}/assets/logos/poweredByGLIFOS.png`}
                alt="poweredByGLIFOS"
                width="63"
                height="30"
                className="rounded"
              />
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
